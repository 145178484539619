<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div
        class="make-announcement"
        v-if="this.isRole === '系统管理员' ? true : false"
      >
        <el-button type="primary" size="mini" @click="makeAnnouncement"
          >发布公告</el-button
        >
      </div>
      <div class="message-table">
        <el-table :data="announcementList" border style="width: 100%">
          <el-table-column prop="title" label="标题" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.title }}</span>
              <span style="color:red;margin-left: 5px;">{{
                scope.row.isRead === 1 ? '' : '【未读】'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdDatetime"
            label="发布时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            v-if="this.isRole === '系统管理员'"
          >
            <template slot-scope="scope">
              <div class="cell-edit-union">
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="read(scope.row.id)"
                  >阅读</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="editAnnouncement(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="deleteAnnouncement(scope.row.id, scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-else>
            <template slot-scope="scope">
              <div class="cell-edit-union">
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="read(scope.row.id)"
                  >阅读</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="list-content-pagination">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="messagePagination.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="messagePagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="messagePagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import Combination from '@/components/Combination.vue';
import { mapActions } from 'vuex';
import { getCookie } from '@/util/userLoginFn';
import { getLocalStorage } from '@/util/userLoginFn';
import {
  getAnnouncementList,
  deleteAnnouncementDetails,
} from '@/service/moudules/platformApi';
export default {
  components: {
    Combination,
  },
  data() {
    return {
      getCookie,
      getLocalStorage,
      title: '文章列表',
      announcementList: [],
      messagePagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      isRole: '',
    };
  },
  methods: {
    ...mapActions('individualcenter', ['getListUnreadFn']),
    sizeChange() {},
    currentChange() {},
    read(id) {
      console.log(id);
      this.$router.push({
        name: 'administrative',
        query: {
          id,
        },
      });
    },
    makeAnnouncement() {
      this.$router.push({
        path: '/editor',
      });
    },

    async getAnnouncement(d) {
      try {
        let params = {
          sessionId: this.getCookie('sessionId'),
          text: '',
          title: '',
        };

        let { data, code, msg } = await getAnnouncementList(params);
        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        if (!d)
          this.$message({
            type: 'success',
            message: '获取公告列表成功',
          });
        this.announcementList = data === null ? [] : data;
      } catch (error) {
        console.log(error);
      }
    },

    async deleteAnnouncement(id, row) {
      try {
        let { data, code, msg } = await deleteAnnouncementDetails(id);
        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        if(!row.isRead){
          // 如果这条信息还未读  那么更新全局公告
          this.getListUnreadFn()
        }
        this.$message({
          type: 'success',
          message: '删除公告列表成功',
        });
        this.getAnnouncement('delete');
      } catch (error) {
        console.log(error);
      }
    },
    editAnnouncement(row) {
      console.log(row);
      this.$router.push({
        path: '/editor',
        query: { ...row, isEdit: 'edit' },
      });
    },
  },
  created() {
    this.isRole = this.getLocalStorage('name');

    this.getAnnouncement();
  },
};
</script>
<style lang="less">
.make-announcement {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
.message-table {
  padding: 15px;
}
.list-content-pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
